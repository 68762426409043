import { PAYMENT_ORDER_STATUS, PAYMENT_CRYPTO_TRANSFER_STATUS } from '@/utils/key-mapping'

export default {
  methods: {
    formatPaymentOrderStatus(order) {
      let statusEl

      switch (order.status) {
        case PAYMENT_ORDER_STATUS.invalid:
          statusEl = '<p class="text-bold text-uppercase text-error">Invalid</p>'
          break
        case PAYMENT_ORDER_STATUS.initial:
          statusEl = '<p class="text-bold text-uppercase text-warning text-uppercase">ready to review</p>'
          break
        case PAYMENT_ORDER_STATUS.pending:
          statusEl = '<p class="text-bold text-uppercase text-warning text-uppercase">ready to review</p>'
          break
        case PAYMENT_ORDER_STATUS.failed:
          statusEl = '<p class="text-bold text-error text-uppercase">Failed</p>'
          break
        case PAYMENT_ORDER_STATUS.imageTransferSubmited:
          statusEl =
            '<p class="text-bold text-uppercase text-warning text-uppercase">need to verify<br>transfer amount</p>'
          break
        case PAYMENT_ORDER_STATUS.paid:
          statusEl = '<p class="text-bold text-uppercase text-gray text-uppercase">Paid</p>'
          break
        case PAYMENT_ORDER_STATUS.success:
          if (order.statusCryptoTransfer === PAYMENT_CRYPTO_TRANSFER_STATUS.invalid) {
            statusEl = '<p class="text-bold text-success text-uppercase">successfully<br>approved</p>'
          }

          if (order.statusCryptoTransfer === PAYMENT_CRYPTO_TRANSFER_STATUS.pending) {
            statusEl = '<p class="text-bold text-warning text-uppercase">pending crypto<br>transfer</p>'
          }

          if (order.statusCryptoTransfer === PAYMENT_CRYPTO_TRANSFER_STATUS.failed) {
            statusEl = '<p class="text-bold text-danger text-uppercase">failed crypto<br>transfer</p>'
          }

          if (order.statusCryptoTransfer === PAYMENT_CRYPTO_TRANSFER_STATUS.success) {
            statusEl = '<p class="text-bold text-success text-uppercase">success crypto<br>transfer</p>'
          }
          break
        case PAYMENT_ORDER_STATUS.cancel:
          statusEl = '<p class="text-bold text-uppercase">cancel</p>'
          break
        case PAYMENT_ORDER_STATUS.refund:
          statusEl = '<p class="text-bold text-primary text-uppercase text-nowrap">refunded<br>transfer amount</p>'
          break
        case PAYMENT_ORDER_STATUS.expire:
          statusEl = '<p class="text-bold text-uppercase text-warning text-uppercase">ready to review</p>'
          break
        case PAYMENT_ORDER_STATUS.approve:
          statusEl = '<p class="text-bold text-success text-uppercase">successfully<br>approved</p>'
          break
        case PAYMENT_ORDER_STATUS.reject:
          statusEl = '<p class="text-bold text-error text-uppercase">Rejected</p>'
          break
      }

      return statusEl
    }
  }
}
