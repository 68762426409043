<template>
  <div>
    <div v-if="paymentOrder">
      <div class="d-flex">
        <h2 class="headline--md text-bold text-uppercase">detail of bank transfer</h2>
        <!-- <div
          v-if="shouldCoundDown"
          class="d-flex">
          <p class="mr-8">Order will end in</p>
          <vue-countdown-timer
            ref="countdownTimer"
            class="text-extra-bold"
            :start-time="paymentOrder.created_at_milliseconds"
            :end-time="paymentOrder.expire_at_milliseconds"
            end-text="Expired"
            seconds-txt="" />
        </div> -->
      </div>

      <div class="box box--md box--white mt-12">
        <table class="table table--list three-items">
          <tbody>
            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Email</p>
                  <div class="clipboard-copy">
                    <h2 class="headline--md mt-4 clipboard-copy__text d-inline-block">
                      <span class="clipboard-copy__text-inner">{{ paymentOrder.user.email }}</span>
                      <copy-clipboard :is-external="false" @on-copy="doCopy($event)" />
                    </h2>
                  </div>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Full Name</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.user.fullName }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Mobile Number</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.user.countryCode }} {{ paymentOrder.user.phone }}</h2>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Order No.</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.uid }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Bought crypto amount (TOKO)</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.amountCrypto | float_number_with_commas }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Transaction No.</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.bankTnxNumber }}</h2>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Spending Amount (Rp)</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.amountCurrency | float_number_with_commas }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Payment Fee (Rp)</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.fee | float_number_with_commas }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Amount to Receive (Rp)</p>
                  <div class="clipboard-copy">
                    <h2 class="headline--md mt-4 clipboard-copy__text d-inline-block">
                      <span class="clipboard-copy__text-inner d-hidden">{{
                        paymentOrder.amountCurrency + paymentOrder.fee
                      }}</span>
                      <span>{{ (paymentOrder.amountCurrency + paymentOrder.fee) | float_number_with_commas }}</span>
                      <copy-clipboard :is-external="false" @on-copy="doCopy($event)" />
                    </h2>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Payment Method</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.paymentType | format_payment_type }}</h2>
                </div>
              </td>

              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Order time (UTC)</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.createdAtMilliseconds | moment_lts }}</h2>
                </div>
              </td>

              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Expired time (UTC)</p>
                  <h2 class="headline--md mt-4">{{ paymentOrder.expireAtMilliseconds | moment_lts }}</h2>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Status</p>
                  <h2 class="headline--md mt-4" v-html="formatPaymentOrderStatus(paymentOrder)" />
                </div>
              </td>

              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Receipt Picture</p>
                  <img
                    v-if="paymentOrder.picBankTransferUrl"
                    class="link--clone mt-4"
                    width="160"
                    @click="isImageSliderModalOpen = true"
                    :src="picBankTransferUrlBlob"
                    alt="amount transfer image"
                  />

                  <p v-else class="mt-4">No image</p>
                </div>
              </td>

              <td v-if="currentUser && $auth('Page', currentUser, $accessRule.PaymentOrderReview).canView()">
                <div v-if="needReview" class="statistic">
                  <p class="statistic__title text-warm">Note</p>
                  <textarea
                    v-if="isNoteFormOpen"
                    v-model="params.note"
                    class="form-control mt-4"
                    placeholder="Add a note"
                    rows="4"
                    cols="50"
                  />
                  <button
                    v-else
                    @click="isNoteFormOpen = true"
                    class="btn btn--clear btn--has-icon text-purple text-bold mt-4"
                  >
                    <toko-note-icon />
                    Add a Note
                  </button>
                </div>
                <div v-else>
                  <p class="statistic__title text-warm">Note</p>
                  <p class="mt-4">{{ paymentOrder.adminNote }}</p>
                </div>
              </td>
              <td v-else />
            </tr>

            <tr
              v-if="currentUser && $auth('Page', currentUser, $accessRule.PaymentOrderReview).canView() && needReview"
            >
              <td class="has-border" colspan="3">
                <div class="d-flex pt-16">
                  <div>
                    <p>Click "Approve" if receiving the exact amount needs to get</p>
                    <p>Click "Refunded" if you sent money back to the account</p>
                  </div>
                  <div>
                    <button @click="openReviewPaymentOrderModal({ target: 'reject' })" class="btn btn--red-bordered">
                      Reject
                    </button>
                    <button @click="openReviewPaymentOrderModal({ target: 'refund' })" class="btn btn--purple-bordered">
                      Refunded
                    </button>
                    <button @click="openReviewPaymentOrderModal({ target: 'approve' })" class="btn btn--primary">
                      Approve
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="transaction" class="mt-32">
      <h2 v-if="transaction" class="headline--md text-bold text-uppercase">Crypto transfer information</h2>
      <transaction :transaction="transaction" />
    </div>

    <div v-if="reviewer" class="mt-32">
      <h2 v-if="reviewer" class="headline--md text-bold text-uppercase mt-32">Reviewer information</h2>
      <div class="box box--md box--white mt-12">
        <table class="table table--list three-items">
          <tbody>
            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Email</p>
                  <h2 class="headline--md mt-4">{{ reviewer.email }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Full Name</p>
                  <h2 class="headline--md mt-4">{{ reviewer.fullName }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Mobile Number</p>
                  <h2 class="headline--md mt-4">{{ reviewer.countryCode }} {{ reviewer.phone }}</h2>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <loading :open="!isLoaded" />

    <modal
      v-if="paymentOrder && paymentOrder.picBankTransferUrl && isImageSliderModalOpen"
      modal-class="modal--md"
      :open.sync="isImageSliderModalOpen"
    >
      <img :src="picBankTransferUrlBlob" style="max-width: 100%" alt="amount transfer image" />
    </modal>

    <confirm-dialog
      v-if="isPaymentOrderReviewModalOpen"
      :open.sync="isPaymentOrderReviewModalOpen"
      confirmText="Are you sure to continue ?"
      confirmButtonText="Continue"
      confirmButtonClass="text-purple"
      @confirm="confirmReviewPaymentOrder"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { PAYMENT_ORDER_STATUS, PAYMENT_CRYPTO_TRANSFER_STATUS } from '@/utils/key-mapping'
import toggleLoading from '@/mixins/toggle-loading'
import copyClipboard from '@/mixins/copy-clipboard'
import paymentOrderStatus from '@/mixins/payment-order-status'
const Transaction = () => import('@/components/Transaction')
const CopyClipboard = () => import('@/components/CopyClipboard')
const TokoNoteIcon = () => import('@/ui/toko-icons/TokoNote')
const Modal = () => import('@/components/Modal')
const ConfirmDialog = () => import('@/components/ConfirmDialog')

export default {
  data() {
    return {
      params: {
        bankTnxNumber: '',
        refCode: '',
        note: ''
      },
      paymentOrderStatus: PAYMENT_ORDER_STATUS,
      paymentCryptoTransferStatus: PAYMENT_CRYPTO_TRANSFER_STATUS,
      isImageSliderModalOpen: false,
      isPaymentOrderReviewModalOpen: false,
      isNoteFormOpen: false,
      reviewTarget: 'approve'
    }
  },

  mixins: [toggleLoading, paymentOrderStatus, copyClipboard],

  computed: {
    ...mapState('payment', ['paymentOrder', 'paymentTransactions', 'picBankTransferUrlBlob']),

    ...mapState('transaction', ['transaction']),

    ...mapState('boUsers', ['boUsers']),

    shouldCoundDown() {
      return (
        this.paymentOrder &&
        this.paymentOrder.status === this.paymentOrderStatus.initial &&
        !this.paymentOrder.picBankTransferUrl
      )
    },

    needReview() {
      return (
        this.paymentOrder &&
        (this.paymentOrder.status === this.paymentOrderStatus.initial ||
          this.paymentOrder.status === this.paymentOrderStatus.pending ||
          this.paymentOrder.status === this.paymentOrderStatus.expire ||
          this.paymentOrder.status === this.paymentOrderStatus.imageTransferSubmited)
      )
    },

    reviewer() {
      let reviewer
      if (this.paymentOrder && this.boUsers && this.paymentOrder.admin_id) {
        reviewer = this.boUsers.filter(item => item.id === this.paymentOrder.adminId)[0]
      }

      return reviewer
    }
  },

  components: {
    Transaction,
    CopyClipboard,
    TokoNoteIcon,
    Modal,
    ConfirmDialog
  },

  methods: {
    ...mapActions('payment', [
      'getPaymentOrder',
      'getPaymentTransactions',
      'approvePaymentOrder',
      'refundPaymentOrder',
      'rejectPaymentOrder'
    ]),

    ...mapMutations('payment', ['CLEAR_PAYMENT_ORDER']),

    ...mapMutations('transaction', ['CLEAR_TRANSACTION_DATA']),

    ...mapActions('boUsers', ['getBoUsers']),

    onGetParamsRequest() {
      return {
        id: parseInt(this.$route.params.id),
        admin_note: this.$sanitize(this.params.note)
      }
    },

    openReviewPaymentOrderModal({ target }) {
      this.reviewTarget = target
      this.isPaymentOrderReviewModalOpen = true
    },

    confirmReviewPaymentOrder() {
      if (this.reviewTarget === 'approve') {
        this.onApprovePaymentOrder()
      } else if (this.reviewTarget === 'reject') {
        this.onRejectPaymentOrder()
      } else {
        this.onRefundPaymentOrder()
      }
    },

    onRefundPaymentOrder() {
      this.toggleLoading()
      this.refundPaymentOrder(this.onGetParamsRequest()).finally(() => {
        this.toggleLoading()
        this.isPaymentOrderReviewModalOpen = false
      })
    },

    onApprovePaymentOrder() {
      this.toggleLoading()
      this.approvePaymentOrder(this.onGetParamsRequest()).finally(() => {
        this.toggleLoading()
        this.isPaymentOrderReviewModalOpen = false
      })
    },

    onRejectPaymentOrder() {
      this.toggleLoading()
      this.rejectPaymentOrder(this.onGetParamsRequest()).finally(() => {
        this.toggleLoading()
        this.isPaymentOrderReviewModalOpen = false
      })
    }
  },

  async created() {
    this.CLEAR_TRANSACTION_DATA()
    this.CLEAR_PAYMENT_ORDER()
    try {
      if (!this.boUsers) await this.getBoUsers()
      await this.getPaymentOrder(this.$route.params.id)
      this.toggleLoading()
    } catch (e) {
      this.toggleLoading()
      throw e
    }
  }
}
</script>
